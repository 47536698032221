<template>
    <div v-if="isLoading" id="preloader" class="uk-position-fixed uk-background-muted uk-position-z-index" style="left: 0; bottom:0; top:0; width: 100%; z-index:99999;">
      <div class="uk-position-center uk-text-center">
        <img src="@/assets/images/Tue_and_Thi_Logo.png" alt="" class="uk-animation-slide-top">
        <!-- <h2 class="uk-text-center uk-margin-remove uk-text-bold uk-h4 uk-animation-slide-bottom">Forever begins now</h2> -->
        <!-- <p class="uk-text-center uk-margin-remove uk-text-bold uk-h4 uk-animation-slide-bottom">We're currently performing some updates to improve your experience.</p>
        <p class="uk-text-center uk-margin-remove uk-text-bold uk-h4 uk-animation-slide-bottom">Please check back soon!</p>
        <br>
        <h2 class="uk-text-center uk-margin-remove uk-text-bold uk-h4 uk-animation-slide-bottom">Thank you for your patience.</h2> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PreloaderComponent',
    data() {
      return {
        isLoading: true
      };
    },
    mounted() {
      // Simulate loading time
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // Adjust the timeout duration as needed
    }
  }
  </script>
  
  <style scoped>
  #preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  
  .uk-position-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uk-animation-slide-top {
    animation: slide-top 1s ease-in-out;
    max-width: 200px; 
    border-radius: 10px;
    /* border-radius: 50%; */
  }
  
  .uk-animation-slide-bottom {
    animation: slide-bottom 1s ease-in-out;
    font-family: preload-font;
    font-size: 1.5rem;
    /* font-size: 4rem; */
  }
  
  @keyframes slide-top {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slide-bottom {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>