<template>
  <section id="banner" class="section-banner">
    <div class="banner-container">
      <img src="@/assets/images/bg-banner.png" alt="" class="banner-bg">
      <div class="banner-slide swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(image, index) in images" :key="index">
            <img :src="image" />
          </div>
        </div>
      </div>
      <div class="banner-name-left">
        <h2 class="groom_name" data-aos="fade-right" data-aos-duration="2000">Minh Tuệ</h2>
      </div>
      <div class="banner-name-right">
        <h2 class="bride_name" data-aos="fade-left" data-aos-duration="2000">Bảo Thi</h2>
      </div>
    </div>
    <!-- <div class="date-group" data-aos="fade-up" data-aos-duration="2000">
      <div class="banner-date">{{weddingLocationHue}}, {{weddingDayHue}}</div>
      <div class="banner-date">{{weddingLocationHN}}, {{weddingDayHN}}</div>
    </div> -->
  </section>
</template>

<script>
// import Swiper from 'swiper/bundle';
import Swiper from 'swiper';
import '@/assets/css/swiper-bundle.min.css';

import anh_bia from '@/assets/images/anh_bia.jpg';

export default {
  name: 'BannerComponent',
  data() {
    return {
      images: [
        anh_bia,
      ],
      weddingDayHue : new Date('2024-12-30'),
      weddingDayHN: new Date('2025-01-09'),
      weddingLocationHue: 'Huế',
      weddingLocationHN: 'Hà Nội',
    };
  },
  computed: {
    weddingLocation() {
      return this.weddingDayHue > new Date ? 'Huế' : 'Hà Nội';
    },
    weddingDay() {
      const wedDay = new Date > this.weddingDayHue ? this.weddingDayHN : this.weddingDayHue;
      return wedDay.toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
  mounted() {
    new Swiper('.banner-slide', {
      effect: 'fade',
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
    });
  }
}
</script>

<style scoped>
.section-banner {
  position: relative;
  display: grid;
  padding: 80px 0;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: white;
}

.banner-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: clamp(1.3rem, 1.8rem + 3.5vw, 4rem);
  width: 90%;
  max-width: 320px;
}

.banner-bg {
  position: absolute;
  object-fit: cover;
  object-position: center;
}

.banner-slide {
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 4;
  overflow: hidden;
  border-radius: 999px 999px 0 0;
  margin-top: 20px;
  min-width: 200px;
}

.banner-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-name-left,
.banner-name-right {
  position: absolute;
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  bottom: 30%;
  font-family: var(--title-font);
  text-transform: uppercase;

  h2 {
    background-color: white;
    padding: 0px 20px;
  }
}

.banner-name-left {
  transform: rotate(-90deg) translateY(-50%);
  left: -90px;
}

.banner-name-right {
  transform: rotate(90deg) translateY(-50%);
  right: -90px;
}

.date-group {
  display: flex;
  position: relative;
  justify-content: center;
  justify-items: center;
  font-size: 24px;
  /* top: -50%; */
  font-weight: bold;
  font-family: var(--title-font);
  background-color: white;
  padding: 16px;
}

.banner-date {
  font-size: 24px;
  color: black;
}
</style>