<template>
  <PreloaderComponent />
  <BannerComponent />
  <NameComponent />
  <AboutComponent />
  <TimelineComponent />
  <AlbumComponent />
  <VideoComponent />
  <InvitationComponent />
  <CountdownComponent />
  <MessageComponent />
  <FooterComponent />
</template>

<script>
import PreloaderComponent from '@/components/preloader/PreloaderComponent.vue';
import BannerComponent from '@/components/banner/BannerComponent.vue';
// import NameComponent from '@/components/name/NameComponent.vue';
// import AboutComponent from '@/components/about/AboutComponent.vue';
// import TimelineComponent from '@/components/timeline/TimelineComponent.vue';
// import AlbumComponent from '@/components/album/AlbumComponent.vue';
// import VideoComponent from '@/components/video/VideoComponent.vue';
import InvitationComponent from '@/components/invitation/InvitationComponent.vue';
// import CountdownComponent from '@/components/countdown/CountdownComponent.vue';
// import MessageComponent from '@/components/message/MessageComponent.vue';
// import FooterComponent from '@/components/footer/FooterComponent.vue';

export default {
  name: 'HomePage',
  components: {
    PreloaderComponent,
    BannerComponent,
    // NameComponent,
    // AboutComponent,
    // TimelineComponent,
    // AlbumComponent,
    // VideoComponent,
    InvitationComponent,
    // CountdownComponent,
    // MessageComponent,
    // FooterComponent
  }
}
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>

<!-- <template>
    <div id="app">
      <HeaderComponent />
      <WelcomeBannerComponent />
      <EventDetailsComponent />
      <RSVPFormComponent />
      <ContactInfoComponent />
    </div>
  </template>
  
  <script>
  import HeaderComponent from '../components/header/HeaderComponent.vue';
  import WelcomeBannerComponent from '../components/welcome_banner/WelcomeBannerComponent.vue';
  import EventDetailsComponent from '../components/event_details/EventDetailsComponent.vue';
  import RSVPFormComponent from '../components/rsvp_form/RsvpFormComponent.vue';
  import ContactInfoComponent from '../components/contact_info/ContactInfoComponent.vue';
  
  export default {
    name: 'HomePage',
    components: {
      HeaderComponent,
      WelcomeBannerComponent,
      EventDetailsComponent,
      RSVPFormComponent,
      ContactInfoComponent
    }
  }
  </script>
  
  <style>
  #app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  </style>
   -->