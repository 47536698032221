<template>
  <section id="invitation" class="section-invitation">
    <div class="container">
      <!-- <div class="section-heading">
        <h2 class="section-title">Invitation</h2>
      </div> -->
      <div class="uk-section">
        <div class="uk-grid" uk-grid>
          <div class="invitation-card" v-for="(invitation, index) in invitations" :key="index">
            <div class="invitation-media">
              <img :src="invitation.image" :alt="invitation.title">
            </div>
            <div class="invitation-body">
              <div class="invitation-headlines">
                <h3 class="invitation-title">{{ invitation.title }}</h3>
                <p class="invitation-location">{{ invitation.location }}</p>
              </div>
              <div class="uk-light">
                <a :href="'tel:' + invitation.phone" class="uk-icon-button uk-margin-small-right" target="_blank"
                  uk-icon="receiver"></a>
                <a :href="invitation.map" class="uk-icon-button uk-margin-small-right" target="_blank"
                  uk-icon="location"></a>
                <!-- <a data-fancybox="" data-src="#gift-container" href="" class="uk-icon-button"><i class="ri-gift-line"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InvitationComponent',
  data() {
    return {
      invitations: [
        {
          title: 'LỄ VU QUY',
          image: require('@/assets/images/hue.jpg'),
          location: 'Trung Tâm Sự Kiện Asia Palace, Huế',
          map: 'https://maps.app.goo.gl/2LS9K9GBDnV5DSVv8'
        },
        {
          title: 'LỄ THÀNH HÔN',
          image: require('@/assets/images/hanoi.jpg'),
          location: 'Trung tâm Tiệc cưới & Hội Nghị Mipec Palace, Hà Nội',
          map: 'https://maps.app.goo.gl/mhxNuk8UGFYjDzH79'
        }
      ]
    };
  }
}
</script>

<style scoped>
.section-invitation {
  padding: 50px 0;
  background-color: #f9f9f9;
  width: 90%;
  margin: auto;
}

.section-heading {
  text-align: center;
  margin-bottom: 30px;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
}

.uk-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uk-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-left: unset;

  * {
    padding-left: 0;
  }
}

.invitation-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.invitation-media img {
  width: 100%;
  height: auto;
}

.invitation-body {
  padding: 20px;
  text-align: center;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invitation-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.invitation-location {
  margin-top: 0;
}

.uk-light .uk-icon-button {
  position: relative;
  bottom: 0;
  padding-bottom: 200px;
  margin: 0 5px;
  margin-top: 1rem;
  color: #fff;
  background-color: #333;
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.uk-light .uk-icon-button:hover {
  background-color: #555;
}
</style>